<template>
  <section class="text-page">
    <div class="inner">
      <template v-if="$store.getters.LANG === 'ua'">
        <h1>Положення про обробку і захист персональних даних у базах персональних даних, володарем яких є продавець</h1>
        <br>
        <h3>Зміст</h3>
        <ul>
          <li>
            <p>1.</p>
            <p>Загальні поняття та сфера застосування.</p>
          </li>
          <li>
            <p>2.</p>
            <p>Перелік баз персональних даних.</p>
          </li>
          <li>
            <p>3.</p>
            <p>Мета обробки персональних даних.</p>
          </li>
          <li>
            <p>4.</p>
            <p>Порядок обробки персональних даних: отримання згоди, повідомлення про права та дії з персональними даними суб’єкта персональних даних.</p>
          </li>
          <li>
            <p>5.</p>
            <p>Місцезнаходження бази персональних даних.</p>
          </li>
          <li>
            <p>6.</p>
            <p>Умови розкриття інформації про персональні дані третім особам.</p>
          </li>
          <li>
            <p>7.</p>
            <p>Захист персональних даних: способи захисту, відповідальна особа, працівники, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових обов’язків, строк зберігання персональних даних.</p>
          </li>
          <li>
            <p>8.</p>
            <p>Права суб’єкта персональних даних.</p>
          </li>
          <li>
            <p>9.</p>
            <p>Порядок роботи з запитами суб’єкта персональних даних.</p>
          </li>
          <li>
            <p>10.</p>
            <p>Державна реєстрація бази персональних даних.</p>
          </li>
        </ul>
        <h3>1. Загальні поняття та сфера застосування.</h3>
        <p>1.1. Визначення термінів:</p>
        <p><b>база персональних даних</b> — іменована сукупність упорядкованих персональних даних в електронній формі та/або у формі картотек персональних даних;</p>
        <p><b>відповідальна особа</b> — визначена особа, яка організовує роботу, пов’язану із захистом персональних даних при їх обробці, відповідно до закону;</p>
        <p><b>володілець бази персональних даних</b> — фізична або юридична особа, якій законом або за згодою суб’єкта персональних даних надано право на обробку цих даних, яка затверджує мету обробки персональних даних у цій базі даних, встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законом;</p>
        <p>Державний реєстр баз персональних даних — єдина державна інформаційна система збору, накопичення та обробки відомостей про зареєстровані бази персональних даних;</p>
        <p><b>загальнодоступні джерела персональних даних</b> — довідники, адресні книги, реєстри, списки, каталоги, інші систематизовані збірники відкритої інформації, які містять персональні дані, розміщені та опубліковані з відома суб’єкта персональних даних.</p>
        <p>Не вважаються загальнодоступними джерелами персональних даних соціальні мережі та інтернет-ресурси, в яких суб’єкт персональних даних залишають свої персональні дані (окрім випадків, коли суб’єктом персональних даних прямо зазначено, що персональні дані розміщені з метою їх вільного поширення та використання);</p>
        <p><b>згода суб’єкта персональних даних</b> — будь-яке документоване, добровільне волевиявлення фізичної особи щодо надання дозволу на обробку її персональних даних відповідно до сформульованої мети їх обробки;</p>
        <p><b>знеособлення персональних даних</b> — вилучення відомостей, які дають змогу ідентифікувати особу;</p>
        <p><b>обробка персональних даних</b> — будь-яка дія або сукупність дій, здійснених повністю або частково в інформаційній (автоматизованій) системі та/або в картотеках персональних даних, які пов’язані зі збиранням, реєстрацією, накопиченням, зберіганням, адаптуванням, зміною, поновленням, використанням і поширенням (розповсюдженням, реалізацією, передачею), знеособленням, знищенням відомостей про фізичну особу;</p>
        <p><b>персональні дані</b> — відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована;</p>
        <p><b>розпорядник бази персональних даних</b> — фізична чи юридична особа, якій володільцем бази персональних даних або законом надано право обробляти ці дані.</p>
        <p>Не є розпорядником бази персональних даних особа, якій володільцем та/або розпорядником бази персональних даних доручено здійснювати роботи технічного характеру з базою персональних даних без доступу до змісту персональних даних;</p>
        <p><b>суб’єкт персональних даних</b> — фізична особа, стосовно якої відповідно до закону здійснюється обробка її персональних даних;</p>
        <p><b>третя особа</b> — будь-яка особа, за винятком суб’єкта персональних даних, володільця чи розпорядника бази персональних даних та уповноваженого державного органу з питань захисту персональних даних, якій володільцем чи розпорядником бази персональних даних здійснюється передача персональних даних відповідно до закону;</p>
        <p><b>особливі категорії даних</b> — персональні дані про расове або етнічне походження, політичні, релігійні або світоглядні переконання, членство в політичних партіях та професійних спілках, а також даних, що стосуються здоров’я чи статевого життя.</p>
        <p>1.2. Дане Положення обов’язкове для застосування відповідальною особою та співробітниками продавця, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових обов’язків.</p>
        <h3>2. Перелік баз персональних даних.</h3>
        <p>2.1. Продавець є власником наступних баз персональних даних:</p>
        <ul><li><p>&#x2022;</p><p>база персональних даних контрагенти.</p></li></ul>
        <h3>3. Мета обробки персональних даних.</h3>
        <p>3.1. Метою обробки персональних даних у системі є зберігання та обслуговування даних контрагентів, відповідно до статей 6, 7 Закону України «Про захист персональних даних».</p>
        <p>3.2. Метою обробки персональних даних є забезпечення реалізації цивільно-правових відносин, надання/ отримання та здійснення розрахунків за придбані товари/послуги відповідно до Податкового кодексу України, Закону України «Про бухгалтерський облік та фінансову звітність в Україні».</p>
        <h3>4. Порядок обробки персональних даних: отримання згоди, повідомлення про права та дії з персональними даними суб’єкта персональних даних.</h3>
        <p>4.1. Згода суб’єкта персональних даних має бути добровільним волевиявленням фізичної особи щодо надання дозволу на обробку її персональних даних відповідно до сформульованої мети їх обробки. Згода суб’єкта персональних даних може бути надана у наступних формах:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>документ на паперовому носії з реквізитами, що дає змогу ідентифікувати цей документ і фізичну особу;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>електронний документ, який має містити обов’язкові реквізити, що дають змогу ідентифікувати цей документ та фізичну особу. Добровільне волевиявлення фізичної особи щодо надання дозволу на обробку її персональних даних доцільно засвідчувати електронним підписом суб’єкта персональних даних.</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>відмітка на електронній сторінці документа чи в електронному файлі, що обробляється в інформаційній системі на основі документованих програмно-технічних рішень.</p>
          </li>
        </ul>
        <p>4.2. Згода суб’єкта персональних даних надається під час оформлення цивільно-правових відносин відповідно до чинного законодавства.</p>
        <p>4.3. Повідомлення суб’єкта персональних даних про включення його персональних даних до бази персональних даних, права, визначені Законом України «Про захист персональних даних», мету збору даних та осіб, яким передаються його персональні дані здійснюється під час оформлення цивільно-правових відносин відповідно до чинного законодавства.</p>
        <p>4.4. Обробка персональних даних про расове або етнічне походження, політичні, релігійні або світоглядні переконання, членство в політичних партіях та професійних спілках, а також даних, що стосуються здоров’я чи статевого життя (особливі категорії даних) забороняється.</p>
        <h3>5. Місцезнаходження бази персональних даних.</h3>
        <p>5.1. Вказані у розділі 2 цього Положення бази персональних даних знаходяться за адресою продавця.</p>
        <h3>6. Умови розкриття інформації про персональні дані третім особам.</h3>
        <p>6.1. Порядок доступу до персональних даних третіх осіб визначається умовами згоди суб’єкта персональних даних, наданої володільцю бази персональних даних на обробку цих даних, або відповідно до вимог закону.</p>
        <p>6.2. Доступ до персональних даних третій особі не надається, якщо зазначена особа відмовляється взяти на себе зобов’язання щодо забезпечення виконання вимог Закону України «Про захист персональних даних» або неспроможна їх забезпечити.</p>
        <p>6.3. Суб’єкт відносин, пов’язаних з персональними даними, подає запит щодо доступу (далі — запит) до персональних даних володільцю бази персональних даних.</p>
        <p>6.4. У запиті зазначаються:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>прізвище, ім’я та по батькові, місце проживання (місце перебування) і реквізити документа, що посвідчує фізичну особу, яка подає запит (для фізичної особи — заявника);</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>найменування, місцезнаходження юридичної особи, яка подає запит, посада, прізвище, ім’я та по батькові особи, яка засвідчує запит; підтвердження того, що зміст запиту відповідає повноваженням юридичної особи (для юридичної особи — заявника);</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>прізвище, ім’я та по батькові, а також інші відомості, що дають змогу ідентифікувати фізичну особу, стосовно якої робиться запит;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>відомості про базу персональних даних, стосовно якої подається запит, чи відомості про володільця чи розпорядника цієї бази;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>перелік персональних даних, що запитуються;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>мета запиту.</p>
          </li>
        </ul>
        <p>6.5. Строк вивчення запиту на предмет його задоволення не може перевищувати десяти робочих днів з дня його надходження.</p>
        <p>Протягом цього строку володілець бази персональних даних доводить до відома особи, яка подає запит, що запит буде задоволене або відповідні персональні дані не підлягають наданню, із зазначенням підстави, визначеної у відповідному нормативно-правовому акті.</p>
        <p>Запит задовольняється протягом тридцяти календарних днів з дня його надходження, якщо інше не передбачено законом.</p>
        <p>6.6. Усі працівники володільця бази персональних даних зобов’язані додержуватися вимог конфіденційності щодо персональних даних та інформації щодо рахунків у цінних паперах та обігу цінних паперів.</p>
        <p>6.7. Відстрочення доступу до персональних даних третіх осіб допускається у разі, якщо необхідні дані не можуть бути надані протягом тридцяти календарних днів з дня надходження запиту. При цьому загальний термін вирішення питань, порушених у запиті, не може перевищувати сорока п’яти календарних днів.</p>
        <p>6.8. Повідомлення про відстрочення доводиться до відома третьої особи, яка подала запит, у письмовій формі з роз’ясненням порядку оскарження такого рішення.</p>
        <p>6.9. У повідомленні про відстрочення зазначаються:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>прізвище, ім’я та по батькові посадової особи;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>дата відправлення повідомлення;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>причина відстрочення;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>строк, протягом якого буде задоволене запит.</p>
          </li>
        </ul>
        <p>6.10. Відмова у доступі до персональних даних допускається, якщо доступ до них заборонено згідно із законом.</p>
        <p>6.11. У повідомленні про відмову зазначаються:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>прізвище, ім’я, по батькові посадової особи, яка відмовляє у доступі;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>дата відправлення повідомлення;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>причина відмови.</p>
          </li>
        </ul>
        <p>6.12. Рішення про відстрочення або відмову із доступі до персональних даних може бути оскаржено до уповноваженого державного органу з питань захисту персональних даних, інших органів державної влади та органів місцевого самоврядування, до повноважень яких належить здійснення захисту персональних даних, або до суду.</p>
        <h3>7. Захист персональних даних: способи захисту, відповідальна особа, працівники, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових обов’язків, строк зберігання персональних даних.</h3>
        <p>7.1. Володільця бази персональних даних обладнано системними і програмно-технічними засобами та засобами зв’язку, які запобігають втратам, крадіжкам, несанкціонованому знищенню, викривленню, підробленню, копіюванню інформації і відповідають вимогам міжнародних та національних стандартів.</p>
        <p>7.2. Відповідальна особа організовує роботу, пов’язану із захистом персональних даних при їх обробці, відповідно до закону. Відповідальна особа визначається наказом Володільця бази персональних даних.</p>
        <p>Обов’язки відповідальної особи щодо організації роботи, пов’язаної із захистом персональних даних при їх обробці зазначаються у посадовій інструкції.</p>
        <p>7.3. Відповідальна особа зобов’язана:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>знати законодавство України в сфері захисту персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>розробити процедури доступу до персональних даних співробітників відповідно до їхніх професійних чи службових або трудових обов’язків;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>забезпечити виконання співробітниками Володільця бази персональних даних вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, що регулюють діяльність Володільця бази персональних даних щодо обробки і захисту персональних даних у базах персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>розробити порядок (процедуру) внутрішнього контролю за дотриманням вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, що регулюють діяльність Володільця бази персональних даних щодо обробки і захисту персональних даних у базах персональних даних, який, зокрема, повинен містити норми щодо періодичності здійснення такого контролю;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>повідомляти Володільця бази персональних даних про факти порушень співробітниками вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, що регулюють діяльність Володільця бази персональних даних щодо обробки і захисту персональних даних у базах персональних даних у термін не пізніше одного робочого дня з моменту виявлення таких порушень;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>забезпечити зберігання документів, що підтверджують надання суб’єктом персональних даних згоди на обробку своїх персональних даних та повідомлення вказаного суб’єкта про його права.</p>
          </li>
        </ul>
        <p>7.4. З метою виконання своїх обов’язків відповідальна особа має право:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>отримувати необхідні документи, у тому числі накази й інші розпорядчі документи, видані Володільцем бази персональних даних , пов’язані із обробкою персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>робити копії з отриманих документів, у тому числі копії файлів, будь-яких записів, що зберігаються в локальних обчислювальних мережах і автономних комп’ютерних системах;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>брати участь в обговоренні виконуваних ним обов’язків організації роботи, пов’язаної із захистом персональних даних при їх обробці;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>вносити на розгляд пропозиції щодо покращення діяльності та вдосконалення методів роботи, подавати зауваження та варіанти усунення виявлених недоліків у процесі обробки персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>одержувати пояснення з питань здійснення обробки персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>підписувати та візувати документи в межах своєї компетенції.</p>
          </li>
        </ul>
        <p>7.5. Працівники, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових (трудових) обов’язків зобов’язані дотримуватись вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, щодо обробки і захисту персональних даних у базах персональних даних.</p>
        <p>7.6. Працівники, що мають доступ до персональних даних, у тому числі, здійснюють їх обробку зобов’язані не допускати розголошення у будь-який спосіб персональних даних, які їм було довірено або які стали відомі у зв’язку з виконанням професійних чи службових або трудових обов’язків. Таке зобов’язання чинне після припинення ними діяльності, пов’язаної з персональними даними, крім випадків, установлених законом.</p>
        <p>7.7.Особи, що мають доступ до персональних даних, у тому числі, здійснюють їх обробку у разі порушення ними вимог Закону України «Про захист персональних даних» несуть відповідальність згідно законодавства України.</p>
        <p>7.8. Персональні дані не повинні зберігатися довше, ніж це необхідно для мети, для якої такі дані зберігаються, але у будь-якому разі не довше строку зберігання даних, визначеного згодою суб’єкта персональних даних на обробку цих даних.</p>
        <h3>8. Права суб’єкта персональних даних.</h3>
        <p>8.1. Суб’єкт персональних даних має право:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>знати про місцезнаходження бази персональних даних, яка містить його персональні дані, її призначення та найменування, місцезнаходження та / або місце проживання (перебування) володільця чи розпорядника цієї бази або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані, що містяться у відповідній базі персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>на доступ до своїх персональних даних, що містяться у відповідній базі персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи зберігаються його персональні дані у відповідній базі персональних даних, а також отримувати зміст його персональних даних, які зберігаються;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>пред’являти вмотивовану вимогу із запереченням проти обробки своїх персональних даних органами державної влади, органами місцевого самоврядування при здійсненні їхніх повноважень, передбачених законом;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>пред’являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та розпорядником цієї бази, якщо ці дані обробляються незаконно чи є недостовірними;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>звертатися з питань захисту своїх прав щодо персональних даних до органів державної влади, органів місцевого самоврядування, до повноважень яких належить здійснення захисту персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних.</p>
          </li>
        </ul>
        <h3>9. Порядок роботи з запитами суб’єкта персональних даних.</h3>
        <p>9.1. Суб’єкт персональних даних має право на одержання будь-яких відомостей про себе у будь-якого суб’єкта відносин, пов’язаних з персональними даними, без зазначення мети запиту, крім випадків, установлених законом.</p>
        <p>9.2. Доступ суб’єкта персональних даних до даних про себе здійснюється безоплатно.</p>
        <p>9.3. Суб’єкт персональних даних подає запит щодо доступу (далі — запит) до персональних даних володільцю бази персональних даних.</p>
        <p>У запиті зазначаються:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>прізвище, ім’я та по батькові, місце проживання (місце перебування) і реквізити документа, що посвідчує особу суб’єкта персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>інші відомості, що дають змогу ідентифікувати особу суб’єкта персональних даних;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>відомості про базу персональних даних, стосовно якої подається запит, чи відомості про володільця чи розпорядника цієї бази;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>перелік персональних даних, що запитуються.</p>
          </li>
        </ul>
        <p>9.4. Строк вивчення запиту на предмет його задоволення не може перевищувати десяти робочих днів з дня його надходження.</p>
        <p>9.5. Протягом цього строку володілець бази персональних даних доводить до відома суб’єкта персональних даних, що запит буде задоволене або відповідні персональні дані не підлягають наданню, із зазначенням підстави, визначеної у відповідному нормативно-правовому акті.</p>
        <p>9.6. Запит задовольняється протягом тридцяти календарних днів з дня його надходження, якщо інше не передбачено законом.</p>
        <h3>10. Державна реєстрація бази персональних даних.</h3>
        <p>10.1. Державна реєстрація баз персональних даних здійснюється відповідно до статті 9 Закону України «Про захист персональних даних».</p>
      </template>
      <template v-else>
        <h1>Regulations on the processing and protection of personal data in personal data bases owned by the seller</h1>
        <br>
        <h3>Content</h3>
        <ul>
          <li>
            <p>1.</p>
            <p>General concepts and scope of application.</p>
          </li>
          <li>
            <p>2.</p>
            <p>List of personal data bases.</p>
          </li>
          <li>
            <p>3.</p>
            <p>Purpose of personal data processing.</p>
          </li>
          <li>
            <p>4.</p>
            <p>The procedure for processing personal data: obtaining consent, notification of rights and actions with personal data of the subject of personal data.</p>
          </li>
          <li>
            <p>5.</p>
            <p>Location of the personal data base.</p>
          </li>
          <li>
            <p>6.</p>
            <p>Terms of disclosure of personal data to third parties.</p>
          </li>
          <li>
            <p>7.</p>
            <p>Protection of personal data: methods of protection, the responsible person, employees who directly process and/or have access to personal data in connection with the performance of their official duties, the period of storage of personal data.</p>
          </li>
          <li>
            <p>8.</p>
            <p>Rights of the subject of personal data.</p>
          </li>
          <li>
            <p>9.</p>
            <p>Procedure for handling requests of the subject of personal data.</p>
          </li>
          <li>
            <p>10.</p>
            <p>State registration of the personal data base.</p>
          </li>
        </ul>
        <h3>1. General concepts and scope of application.</h3>
        <p>1.1. Definition of terms:</p>
        <p>personal data base — a named collection of ordered personal data in electronic form and/or in the form of personal data files;</p>
        <p>responsible person - a designated person who organizes work related to the protection of personal data during their processing, in accordance with the law;</p>
        <p>the owner of the personal data base is a natural or legal person who is granted the right to process this data by law or with the consent of the subject of personal data, who approves the purpose of processing personal data in this database, establishes the composition of this data and the procedures for its processing, unless otherwise determined by law;</p>
        <p>The State Register of Personal Data Bases is a single state information system for collecting, accumulating and processing information on registered personal data bases;</p>
        <p>publicly available sources of personal data — directories, address books, registers, lists, catalogs, other systematized collections of open information, which contain personal data, placed and published with the knowledge of the subject of personal data.</p>
        <p>Social networks and Internet resources in which the subject of personal data leaves their personal data are not considered publicly available sources of personal data (unless the subject of personal data expressly states that the personal data is posted for the purpose of their free distribution and use);</p>
        <p>consent of the subject of personal data - any documented, voluntary expression of will of a natural person regarding the granting of permission for the processing of his personal data in accordance with the formulated purpose of their processing;</p>
        <p>depersonalization of personal data — removal of information that allows identification of a person;</p>
        <p>processing of personal data — any action or set of actions performed in whole or in part in the information (automated) system and/or in personal data files, which are related to the collection, registration, accumulation, storage, adaptation, change, renewal, use and distribution (distribution, implementation, transfer), depersonalization, destruction of information about a natural person;</p>
        <p>personal data - information or a set of information about a natural person who is identified or can be specifically identified;</p>
        <p>the manager of the personal data base is a natural or legal person who is authorized to process this data by the owner of the personal data base or by law.</p>
        <p>A person who is instructed by the owner and/or manager of the personal data base to carry out technical work with the personal data base without access to the content of personal data is not a manager of the personal data base;</p>
        <p>subject of personal data - a natural person, in relation to whom, in accordance with the law, his personal data is processed;</p>
        <p>third party - any person, with the exception of the subject of personal data, the owner or manager of the personal data base and the authorized state body for personal data protection, to whom the owner or manager of the personal data base transfers personal data in accordance with the law;</p>
        <p>special categories of data — personal data about racial or ethnic origin, political, religious or ideological beliefs, membership in political parties and trade unions, as well as data related to health or sex life.</p>
        <p>1.2. This Regulation is mandatory for the responsible person and employees of the seller who directly process and/or have access to personal data in connection with the performance of their official duties.</p>
        <h3>2. List of personal data bases.</h3>
        <p>2.1. The seller is the owner of the following personal data bases:</p>
        <ul><li><p>&#x2022;</p><p>the counterparty's personal data base.</p></li></ul>
        <h3>3. Purpose of personal data processing.</h3>
        <p>3.1. The purpose of processing personal data in the system is the storage and maintenance of counterparty data, in accordance with Articles 6 and 7 of the Law of Ukraine "On Personal Data Protection".</p>
        <p>3.2. The purpose of processing personal data is to ensure the implementation of civil legal relations, provision/receipt and settlement of purchased goods/services in accordance with the Tax Code of Ukraine, the Law of Ukraine "On Accounting and Financial Reporting in Ukraine".</p>
        <h3>4. The procedure for processing personal data: obtaining consent, notification of rights and actions with personal data of the subject of personal data.</h3>
        <p>4.1. The consent of the subject of personal data must be a voluntary expression of the individual's will to grant permission for the processing of his personal data in accordance with the formulated purpose of their processing. The consent of the subject of personal data can be given in the following forms:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>a document on a paper medium with requisites, which makes it possible to identify this document and the natural person;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>an electronic document, which must contain mandatory details that allow identification of this document and a natural person. It is expedient to certify the voluntary expression of the individual's will regarding the granting of permission for the processing of his personal data with the electronic signature of the subject of personal data.</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>a mark on the electronic page of the document or in the electronic file processed in the information system on the basis of documented software and technical solutions.</p>
          </li>
        </ul>
        <p>4.2. The consent of the subject of personal data is given during the registration of civil legal relations in accordance with the current legislation.</p>
        <p>4.3. The notification of the subject of personal data about the inclusion of his personal data in the personal data base, the rights defined by the Law of Ukraine "On the Protection of Personal Data", the purpose of data collection and the persons to whom his personal data is transferred is carried out during the registration of civil legal relations in accordance with the current legislation.</p>
        <p>4.4. The processing of personal data on racial or ethnic origin, political, religious or ideological beliefs, membership in political parties and trade unions, as well as data related to health or sexual life (special categories of data) is prohibited.</p>
        <h3>5. Location of the personal data base.</h3>
        <p>5.1. The personal data base specified in section 2 of this Regulation is located at the address of the seller.</p>
        <h3>6. Terms of disclosure of personal data to third parties.</h3>
        <p>6.1. The procedure for access to personal data of third parties is determined by the terms of the consent of the subject of personal data, given by the owner of the personal data base to the processing of this data, or in accordance with the requirements of the law.</p>
        <p>6.2. Access to personal data is not granted to a third party if the specified person refuses to undertake obligations to ensure the fulfillment of the requirements of the Law of Ukraine "On the Protection of Personal Data" or is unable to ensure them.</p>
        <p>6.3. The subject of relations related to personal data submits a request for access (hereinafter - a request) to personal data to the owner of the personal data base.</p>
        <p>6.4. The request states:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>surname, first name and patronymic, place of residence (place of stay) and details of the document certifying the natural person submitting the request (for a natural person - the applicant);</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>name, location of the legal entity submitting the request, position, surname, first name and patronymic of the person certifying the request; confirmation that the content of the request corresponds to the authority of the legal entity (for a legal entity, the applicant);</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>surname, first name and patronymic, as well as other information that makes it possible to identify the natural person in respect of whom the request is made;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>information about the database of personal data, in relation to which the request is submitted, or information about the owner or manager of this database;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>list of requested personal data;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>the purpose of the request.</p>
          </li>
        </ul>
        <p>6.5. The term of examining the request for its satisfaction may not exceed ten working days from the date of its receipt.</p>
        <p>During this period, the owner of the personal data base informs the person who submits the request that the request will be satisfied or that the relevant personal data are not subject to provision, indicating the grounds defined in the relevant regulatory legal act.</p>
        <p>The request is satisfied within thirty calendar days from the date of its receipt, unless otherwise provided by law.</p>
        <p>6.6. All employees of the owner of the personal data base are obliged to comply with the requirements of confidentiality regarding personal data and information regarding accounts in securities and circulation of securities.</p>
        <p>6.7. Delay of access to personal data of third parties is allowed if the necessary data cannot be provided within thirty calendar days from the date of receipt of the request. At the same time, the total term for solving the issues raised in the request cannot exceed forty-five calendar days.</p>
        <p>6.8. The notice of postponement is brought to the attention of the third party who submitted the request in writing with an explanation of the procedure for appealing such a decision.</p>
        <p>6.9. The notice of postponement states:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>surname, first name and patronymic of the official;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>the date of sending the message;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>the reason for the delay;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>the period during which the request will be satisfied.</p>
          </li>
        </ul>
        <p>6.10. Denial of access to personal data is permitted if access to it is prohibited by law.</p>
        <p>6.11. The rejection notice states:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>surname, first name, patronymic of the official who refuses access;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>the date of sending the message;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>reason for refusal.</p>
          </li>
        </ul>
        <p>6.12. The decision to delay or deny access to personal data may be appealed to the authorized state body for personal data protection, other state authorities and local self-government bodies, whose powers include the protection of personal data, or to a court.</p>
        <h3>7. Protection of personal data: methods of protection, the responsible person, employees who directly process and/or have access to personal data in connection with the performance of their official duties, the period of storage of personal data.</h3>
        <p>7.1. The owner of the personal data base is equipped with system and software and communication tools that prevent loss, theft, unauthorized destruction, distortion, forgery, copying of information and meet the requirements of international and national standards.</p>
        <p>7.2. The responsible person organizes the work related to the protection of personal data during their processing, in accordance with the law. The responsible person is determined by the order of the Owner of the personal database.</p>
        <p>The duties of the responsible person regarding the organization of work related to the protection of personal data during their processing are specified in the job description.</p>
        <p>7.3. The responsible person is obliged to:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>know the legislation of Ukraine in the field of personal data protection;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>develop procedures for access to personal data of employees in accordance with their professional or official or labor duties;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to ensure that the employees of the Owner of the personal data base comply with the requirements of Ukrainian legislation in the field of personal data protection and internal documents regulating the activities of the Owner of the personal data base regarding the processing and protection of personal data in personal data bases;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>develop a procedure (procedure) for internal control over compliance with the requirements of the legislation of Ukraine in the field of personal data protection and internal documents regulating the activities of the Owner of the personal data base regarding the processing and protection of personal data in the personal data bases, which, in particular, should contain norms regarding the periodicity of implementation such control;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>notify the Owner of the personal data base of the facts of violations by employees of the requirements of Ukrainian legislation in the field of personal data protection and internal documents regulating the activities of the Owner of the personal data base regarding the processing and protection of personal data in the personal data bases no later than one working day from the moment of detection of such violations;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to ensure the storage of documents confirming the provision by the subject of personal data of consent to the processing of his personal data and notification of the specified subject about his rights.</p>
          </li>
        </ul>
        <p>7.4. In order to fulfill his duties, the responsible person has the right to:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>receive the necessary documents, including orders and other administrative documents issued by the Owner of the personal data base, related to the processing of personal data;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>make copies of received documents, including copies of files, any records stored in local computer networks and autonomous computer systems;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to participate in the discussion of the duties performed by him in the organization of work related to the protection of personal data during their processing;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>submit for consideration proposals for improvement of activities and improvement of work methods, submit comments and options for elimination of identified deficiencies in the process of personal data processing;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to receive explanations on personal data processing issues;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>sign and certify documents within the limits of their competence.</p>
          </li>
        </ul>
        <p>7.5. Employees who directly process and/or have access to personal data in connection with the performance of their official (labor) duties are obliged to comply with the requirements of the legislation of Ukraine in the field of personal data protection and internal documents, regarding the processing and protection of personal data in personal databases.</p>
        <p>7.6. Employees who have access to personal data, including those who process it, are obliged not to allow the disclosure in any way of personal data entrusted to them or which became known in connection with the performance of professional or official or labor duties of the Such an obligation is effective after they cease activities related to personal data, except for cases established by law.</p>
        <p>7.7. Persons who have access to personal data, including those who process it in case of violation of the requirements of the Law of Ukraine "On the Protection of Personal Data", are responsible in accordance with the legislation of Ukraine.</p>
        <p>7.8. Personal data must not be stored longer than necessary for the purpose for which such data is stored, but in any case not longer than the data storage period determined by the consent of the subject of personal data to the processing of this data.</p>
        <h3>8. Rights of the subject of personal data.</h3>
        <p>8.1. The subject of personal data has the right:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>to know the location of the personal data base that contains his personal data, its purpose and name, location and/or place of residence (residence) of the owner or manager of this database or to give the relevant instructions to obtain this information to persons authorized by him, except for cases established by law ;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to receive information about the conditions for providing access to personal data, in particular information about third parties to whom his personal data contained in the relevant personal data base are transferred;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to access your personal data contained in the relevant personal data base;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>receive no later than thirty calendar days from the date of receipt of the request, except in cases provided by law, an answer on whether his personal data is stored in the relevant personal data base, as well as receive the contents of his personal data that are stored;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>submit a reasoned demand with an objection to the processing of your personal data by state authorities, local self-government bodies in the exercise of their powers provided for by law;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>make a reasoned demand for the change or destruction of your personal data by any owner and administrator of this database, if these data are processed illegally or are unreliable;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>to protect your personal data from illegal processing and accidental loss, destruction, damage due to intentional concealment, failure to provide or untimely provision of data, as well as protection from providing information that is unreliable or disgraces the honor, dignity and business reputation of a physical person individuals;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>apply for the protection of your rights regarding personal data to state authorities, local self-government bodies, whose powers include the protection of personal data;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>apply legal remedies in case of violation of the legislation on the protection of personal data.</p>
          </li>
        </ul>
        <h3>9. Procedure for handling requests of the subject of personal data.</h3>
        <p>9.1. The subject of personal data has the right to receive any information about himself from any subject of relations related to personal data, without specifying the purpose of the request, except for cases established by law.</p>
        <p>9.2. The access of the subject of personal data to personal data is free of charge.</p>
        <p>9.3. The subject of personal data submits a request for access (hereinafter - request) to personal data to the owner of the personal data base.</p>
        <p>The request states:</p>
        <ul>
          <li>
            <p>&#x2022;</p>
            <p>surname, name and patronymic, place of residence (place of stay) and details of the document certifying the identity of the subject of personal data;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>other information that makes it possible to identify the person of the subject of personal data;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>information about the database of personal data, in relation to which the request is submitted, or information about the owner or manager of this database;</p>
          </li>
          <li>
            <p>&#x2022;</p>
            <p>list of requested personal data.</p>
          </li>
        </ul>
        <p>9.4. The term of examining the request for its satisfaction may not exceed ten working days from the date of its receipt.</p>
        <p>9.5. During this period, the owner of the personal data base notifies the subject of personal data that the request will be satisfied or that the relevant personal data are not subject to provision, indicating the grounds defined in the relevant regulatory legal act.</p>
        <p>9.6. The request is satisfied within thirty calendar days from the date of its receipt, unless otherwise provided by law.</p>
        <h3>10. State registration of the personal data base.</h3>
        <p>10.1. State registration of personal data bases is carried out in accordance with Article 9 of the Law of Ukraine "On the Protection of Personal Data".</p>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Policy"
})
</script>

<style scoped lang="scss" src="../assets/scss/policy_terms.scss"></style>
